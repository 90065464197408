import { useEffect, useCallback, useRef, useState } from 'react'
import { navigate } from '@reach/router'
import { useStore, useCroods } from 'croods'
import { useFormState } from 'react-use-form-state'
import { useWindowDimensions, useQueryParam } from '@seasonedsoftware/utils'
import get from 'lodash/get'
import find from 'lodash/find'

import { getFieldProps, isValidForm, isFulfilled } from 'forms/validations'

const STATIC_TITLE = document.title

export const useForm = (initialValues, { fieldsToLook } = {}) => {
  const [formState, form] = useFormState(initialValues)
  const fields = getFieldProps(form, formState)
  const hasErrors =
    !isFulfilled(formState, { fieldsToLook }) || !isValidForm(formState)

  return { formState, fields, hasErrors }
}

export const setTitleFn = (store, title, separator = '|') =>
  store.setState({ siteTitle: { title, separator } }, 'page@title')

const useGlobal = useStore({
  setTitle: setTitleFn,
})

// It will set a base Title for the site. When used along with usePageTitle
// It'll take place after the separator in the title. For instance:
// USAGE: useSiteTitle('Seasoned Site') will result in 'Seasoned Site'
// later, when you use: usePageTitle('Home')
// the result will be 'Home | Seasoned Site'
export const useSiteTitle = (title = STATIC_TITLE, separator = '|') => {
  const [, { setTitle }] = useGlobal('page@title')
  const setPageTitle = useCallback(setTitle, [setTitle])
  useEffect(() => {
    setPageTitle(title, separator)
  }, [separator, setPageTitle, title])
}

export const usePageTitle = text => {
  const [{ siteTitle }] = useGlobal('page@title')
  const { title, separator } = siteTitle || {}
  useEffect(() => {
    document.title = title ? [text, title].join(` ${separator} `) : text
    return () => {
      document.title = title || STATIC_TITLE
    }
  }, [text, separator, title])
}

// It works in two modes:
// Mode 1 -> const { tags, loading, fetch  } = useAdminText(['tag1', 'tag2'])
// Mode 2 -> const { tag1, tag2 } = useAdminText()
// Mode 1 -> If you call useAdminText with an array as an argument, it will
// fetch from the API the tags and store them in adminTexts global state,
// and provide you with an object with all tags, a loading boolean and a fetch
// function (if you need to refetch manually).
// Mode 2 -> If you call useAdminText with no arguments, it will return you an object with
// all the tags available.
// It works that way to enforce a good pratice, where you fetch the tags only
// once in an intial file like App.js, and call useAdminText after to access
// the tags saved on state.
export const useAdminText = (tags = []) => {
  const isRequestMode = tags.length > 0
  const [{ list, fetchingList: loading }, { fetch }] = useCroods({
    name: 'adminTexts',
    customPath: 'admin_texts',
    cache: true,
  })

  useEffect(() => {
    if (isRequestMode) {
      fetch()({ tags })
    }
    // eslint-disable-next-line
  }, [])

  const fetchedTags = list.reduce(
    (total, item) => ({
      ...total,
      ...item,
    }),
    {},
  )

  if (isRequestMode) {
    // NOTE: This can go on useCroods call when croods gets the query bug fix
    const fetchWithQuery = () => fetch()({ tags })
    return { tags: fetchedTags, loading, fetch: fetchWithQuery }
  }

  return fetchedTags
}

// This is a hook to detect if an HTML element has it's content overflown
// notice that it only works if the element has { white-space: nowrap, overflow: hidden }
// styles for convenience this style is passed back as the 3rd returned value in the array
// USAGE: const [overflowing, ref, style] = useDetectOverflow()
// <div ref={ref} style={style}>
//   Long text here, is {overflowing ? '' : 'not '} overflowing
// </div>
export const useDetectOverflow = () => {
  const [overflowing, setOverflowing] = useState(false)
  const { width } = useWindowDimensions()
  const ref = useRef(null)
  useEffect(() => {
    if (ref.current) {
      const { scrollWidth } = ref.current
      const dimensions = ref.current.getBoundingClientRect()
      setOverflowing(Math.ceil(dimensions.width) < scrollWidth)
    }
  }, [width])
  return [overflowing, ref, { whiteSpace: 'nowrap', overflow: 'hidden' }]
}

// It will grab the redirect_to param from the URL and return a function that
// navigates to such path
// USAGE: const redirectBack = useRedirectBack()
// <button onClick={redirectBack} />
export const useRedirectBack = (param = 'redirect_to', defaultPath = '/') => {
  const redirect = useQueryParam(param)
  return () => navigate(redirect || defaultPath)
}

export const useTemplate = templateName => {
  const id = templateName.toLowerCase()

  const [{ list }] = useCroods({
    name: 'conversationTemplates',
  })

  const idToNames = {
    '1:1': 'one_on_one',
    oneOnOne: 'one_on_one',
    feedback: 'upward_feedback',
    upwardfeedback: 'upward_feedback',
  }

  const name = get(idToNames, id, id)

  return find(list, { name })
}
