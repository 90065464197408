import React from 'react'
import { useCroods } from 'croods'

import Loading from 'ui/Loading'

const ConversationTemplateProvider = ({ children }) => {
  const [{ fetchingList }] = useCroods({
    name: 'conversationTemplates',
    path: '/conversation_templates',
    fetchOnMount: true,
    cache: true,
  })

  return fetchingList ? <Loading /> : children
}

export default ConversationTemplateProvider
