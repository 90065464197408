import get from 'lodash/get'
import compact from 'lodash/compact'
import head from 'lodash/head'
import objValues from 'lodash/values'
import pick from 'lodash/pick'
import moment from 'moment'

import { splitAndRemoveSpaces } from 'utils/helpers'

// eslint-disable-next-line
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const validate = validators => (value, values) =>
  head(compact(validators.map(validator => validator(value, values))))

export const confirmation = (name, message = `Deve ser igual a ${name}`) => (
  value,
  values,
) => {
  if (value !== get(values, name)) {
    return message
  }
  return undefined
}

export const validateEmails = (message = 'Email inválido') => value => {
  const emails = splitAndRemoveSpaces(value)
  const messages = compact(emails.map(email()))
  return messages.length > 0 ? message : undefined
}

export const presence = (message = 'É obrigatório') => value =>
  value ? undefined : message

export const richTextPresence = (message = 'É obrigatório') => value => {
  const striped = value ? value.replace(/<[^>]*>?/gm, '').trim() : ''
  return striped.length > 0 ? undefined : message
}

export const email = (message = 'Email inválido') => value =>
  value && !EMAIL_REGEX.test(value) ? message : undefined

export const minLength = (
  chars,
  message = `Mínimo ${chars} caracteres`,
) => value => (value && value.trim().length < chars ? message : undefined)

export const date = (
  format = 'DD/MM/YYYY',
  message = 'Data inválida',
) => value =>
  value &&
  (!moment(value, format).isValid() ||
    value.replace(' ', '').length < format.length)
    ? message
    : undefined

export const futureDate = (
  compareWith,
  { format, message } = {
    format: 'DD/MM/YYYY',
    message: 'Data passada',
  },
) => (value, values) => {
  const compareWithValue = get(values, compareWith)

  if (!value || !compareWithValue) return undefined

  const valueDate = moment(value, format)
  const compareWithDate = moment(compareWithValue, format)

  if (!valueDate.isValid() || !compareWithDate.isValid()) return undefined

  return valueDate.diff(compareWithDate, 'days') < 0 ? message : undefined
}

export const getFieldError = formState => name =>
  get(formState, `touched.${name}`) && get(formState, `errors.${name}`)

export const getFieldProps = (fields, formState) => (
  type,
  name,
  validators = [],
) => {
  const fieldError = getFieldError(formState)
  return {
    ...get(fields, type)({ name, validate: validate(validators) }),
    error: fieldError(name),
  }
}

export const isValidForm = formState =>
  !compact(objValues(formState.errors)).length

export const isEmptyForm = formState =>
  !compact(objValues(formState.values)).length

export const isFulfilled = (formState, { fieldsToLook } = {}) => {
  if (!fieldsToLook)
    return (
      objValues(formState.values).length ===
      compact(objValues(formState.values)).length
    )

  return (
    fieldsToLook.length ===
    compact(objValues(pick(formState.values, fieldsToLook))).length
  )
}
