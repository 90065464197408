import { useCurrentUser } from 'croods-auth'
import get from 'lodash/get'

const menuLinks = [
  {
    name: 'Conversas',
    path: '/',
  },
  {
    name: 'Times',
    path: '/times',
  },
]

const humanResourcesLinks = [
  {
    name: 'Painel do RH',
    path: '/painel',
  },
]

const useMenuLinks = () => {
  const [{ currentUser }] = useCurrentUser()

  return [
    ...(currentUser ? menuLinks : []),
    ...(get(currentUser, 'humanResources') ? humanResourcesLinks : []),
  ]
}

export default useMenuLinks
