import React, { Fragment } from 'react'
import { CssBaseline, Box } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import { usePageId } from '@seasonedsoftware/utils'
import PageTitle from 'core/PageTitle'
import AppBar from 'ui/header/AppBar'

const Layout = ({ pageTitle, location, children }) => {
  const id = usePageId(location)
  const theme = useTheme()
  return (
    <Fragment>
      <AppBar location={location} />
      <Box
        component="section"
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: theme.spacing(5, 0, 3, 0),
          [theme.breakpoints.up('md')]: {
            padding: theme.spacing(5, 0, 8, 0),
          },
        }}
        id={id}
      >
        <CssBaseline />
        {pageTitle && <PageTitle>{pageTitle}</PageTitle>}
        {children}
      </Box>
    </Fragment>
  )
}

export default Layout
