import React, { Fragment, useState } from 'react'
import { Button, Divider, Menu, MenuItem } from '@material-ui/core'
import { navigate } from '@reach/router'
import { useSignOut } from 'croods-auth'

import { colors } from 'theme'

export const handleMenu = setter => event => setter(event.currentTarget)
export const handleClose = setter => () => setter(null)
export const handleLogout = ({ signOut }) => async () => {
  await navigate('/login')
  signOut()
}
export const goTo = destination => () => navigate(destination)

const AccountMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [, signOut] = useSignOut({
    afterSuccess: () => {
      document.location.reload(true)
    },
  })

  return (
    <Fragment>
      <Button
        aria-label="Perfil do usuário"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu(setAnchorEl)}
        color="primary"
        css={{ textTransform: 'capitalize' }}
      >
        Perfil
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose(setAnchorEl)}
      >
        <MenuItem
          css={{ color: colors.dodgerblue }}
          onClick={goTo('/editar-perfil')}
        >
          Editar Perfil
        </MenuItem>
        <Divider />
        <MenuItem
          css={{ color: colors.dodgerblue }}
          onClick={handleLogout({ signOut })}
        >
          Sair
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

export default AccountMenu
