import React, { useState } from 'react'
import { navigate } from '@reach/router'
import { Menu, MenuItem } from '@material-ui/core'
import kebabCase from 'lodash/kebabCase'

import MenuButton from 'ui/header/MenuButton'
import useMenuLinks from 'ui/header/menuLinks'

export const goTo = destination => () => navigate(destination)

export const handleClose = setter => () => setter(null)

export const handleMenuClick = setter => ({ currentTarget }) =>
  setter(currentTarget)

const Item = ({ name, path, links }) => {
  const hasSubmenu = !!links
  const [anchorEl, setAnchorEl] = useState(null)
  const id = `menu-appbar-${kebabCase(name)}`
  const props = hasSubmenu
    ? {
        'aria-label': name,
        'aria-controls': id,
        onClick: handleMenuClick(setAnchorEl),
      }
    : { path }
  return (
    <React.Fragment>
      <MenuButton {...props}>{name}</MenuButton>
      {hasSubmenu && (
        <Menu
          id={id}
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          keepMounted
          open={!!anchorEl}
          onClose={handleClose(setAnchorEl)}
        >
          {links.map(subItem => (
            <MenuItem
              color="primary"
              key={subItem.path}
              onClick={goTo(subItem.path)}
            >
              {subItem.name}
            </MenuItem>
          ))}
        </Menu>
      )}
    </React.Fragment>
  )
}

const DesktopMenu = () => {
  const menuLinks = useMenuLinks()
  return menuLinks.map(item => <Item key={item.name} {...item} />)
}

export default DesktopMenu
