import React from 'react'
import { CircularProgress } from '@material-ui/core'

import Container from 'ui/Container'

export default props => (
  <Container textAlign="center" maxWidth="lg" {...props}>
    <CircularProgress />
  </Container>
)
