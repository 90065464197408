import React from 'react'
import { Link } from '@reach/router'
import { AppBar, Box, Container, Toolbar, useTheme } from '@material-ui/core'
import { useCurrentUser } from 'croods-auth'

import { useDetectOverflow } from 'utils/hooks'
import AccountMenu from 'ui/header/AccountMenu'
import DesktopMenu from 'ui/header/DesktopMenu'
import MobileMenu from 'ui/header/MobileMenu'
import MenuButton from 'ui/header/MenuButton'

const LOGO_URL = 'https://conversas.s3.us-east-2.amazonaws.com/Talks-Logo.png'

const CustomAppBar = ({ location }) => {
  const theme = useTheme()
  const [{ currentUser }] = useCurrentUser()
  const loginUrl = `/login?redirect_to=${location.pathname}`
  const [overflowing, ref, style] = useDetectOverflow()

  return (
    <AppBar
      elevation={0}
      css={{ maxWidth: '100vw', maxHeight: '80px' }}
      position="relative"
      color="secondary"
    >
      <Container maxWidth="md">
        <Toolbar>
          <Link
            css={{
              color: 'inherit',
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
            }}
            to="/"
            title="Go to homepage"
          >
            <img src={LOGO_URL} alt="logo" css={{ height: '30px' }} />
          </Link>
          <Box minWidth={theme.spacing(3)} flexGrow={1} />
          <div
            ref={ref}
            style={style}
            css={overflowing ? { pointerEvents: 'none', opacity: 0 } : {}}
          >
            <DesktopMenu />
          </div>
          {currentUser ? (
            <AccountMenu />
          ) : (
            <MenuButton path={loginUrl}>Login</MenuButton>
          )}
          {overflowing && <MobileMenu />}
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default CustomAppBar
