import React, { useState } from 'react'
import { navigate } from '@reach/router'
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  SwipeableDrawer,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'

import useMenuLinks from 'ui/header/menuLinks'

export const handleClick = ({ path, onClose }) => () => {
  onClose()
  navigate(path)
}

const MenuButton = ({ name, path, onClose }) => (
  <ListItem onClick={handleClick({ path, onClose })} button>
    <ListItemText>{name}</ListItemText>
  </ListItem>
)

export const MenuItem = ({ name, onClose, last, path, links }) => {
  const hasSubmenu = !!links
  return (
    <React.Fragment>
      {hasSubmenu ? (
        <List subheader={<ListSubheader disableSticky>{name}</ListSubheader>}>
          {links.map(item => (
            <MenuButton key={item.path} onClose={onClose} {...item} />
          ))}
        </List>
      ) : (
        <MenuButton onClose={onClose} name={name} path={path} />
      )}
      {last || <Divider />}
    </React.Fragment>
  )
}

export const toggleDrawer = (nextOpen, setOpen) => () => setOpen(nextOpen)

const MobileMenu = () => {
  const [open, setOpen] = useState(false)

  const menuLinks = useMenuLinks()

  return (
    <React.Fragment>
      <IconButton
        onClick={toggleDrawer(true, setOpen)}
        edge="start"
        color="inherit"
        aria-label="Menu"
      >
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false, setOpen)}
        onOpen={toggleDrawer(true, setOpen)}
      >
        <List css={{ minWidth: '80vw' }}>
          {menuLinks.map((item, index) => (
            <MenuItem
              key={item.name}
              onClose={toggleDrawer(false, setOpen)}
              last={index >= menuLinks.length - 1}
              {...item}
            />
          ))}
        </List>
      </SwipeableDrawer>
    </React.Fragment>
  )
}

export default MobileMenu
