import { navigate } from '@reach/router'
import moment from 'moment'
import 'moment/locale/pt-br'

import { colors } from 'theme'

moment.locale('pt-br')

export const forwardParams = path => `${path}${window.location.search}`

export const isHumanResources = currentUser =>
  currentUser && currentUser.humanResources

export const navigateTo = path => () => navigate(path)

export const splitAndRemoveSpaces = str =>
  str ? str.split(',').map(item => item.trim()) : []

export const formatDate = (date, format = 'ddd, DD [de] MMMM [de] YYYY') =>
  moment(date).format(format)

export const displayContributionLevel = contribution =>
  ({ low: 'Baixa', medium: 'Intermediária', high: 'Superior' }[contribution])

export const displayGrowthPath = growthPath =>
  ({ fast: 'Inovação/Desafio', gradual: 'Resiliência/Sensibilidade' }[
    growthPath
  ])

export const displayEvaluation = evaluation =>
  ({
    radical_candor: 'Franqueza com Empatia',
    obnoxious_aggression: 'Franqueza com Indiferença',
    ruinous_empathy: 'Medo de Falar a Verdade',
    manipulative_insincerity: 'Mentira com Indiferença',
  }[evaluation])

export const getUserIdentifier = ({ user }) => {
  if (user.firstName) {
    return `${user.firstName} ${user.lastName || ''}`
  }

  return user.email
}

export const getRoleAndTeams = ({ user, teamName }) => {
  return [user.role, teamName].filter(part => part).join(' | ')
}

export const dateStatus = date => {
  const conversationDate = moment(date)
  const currentDate = moment()

  const diff = conversationDate.diff(currentDate, 'days')

  if (diff > 7) return { status: 'Mais de uma semana', color: colors.green }

  if (diff >= 0 && diff <= 7)
    return { status: 'Menos de uma semana', color: colors.yellow }

  return { status: 'Atrasada', color: colors.red }
}

export default {}
