import React from 'react'
import { Router } from '@reach/router'

import { Route } from 'core/Routes'
import { useSiteTitle } from 'utils/hooks'

const SignIn = React.lazy(() => import('auth/SignIn'))
const ForgotPassword = React.lazy(() => import('auth/ForgotPassword'))
const ForgotSent = React.lazy(() => import('auth/ForgotSent'))
const ResetPassword = React.lazy(() => import('auth/ResetPassword'))
const EditProfile = React.lazy(() => import('account/EditProfile'))
const NotFound = React.lazy(() => import('core/NotFound'))
const AcceptInvite = React.lazy(() => import('invite/accept'))
const NewTeam = React.lazy(() => import('team/New'))
const EditTeam = React.lazy(() => import('team/Edit'))
const MyTeams = React.lazy(() => import('team/list'))
const Users = React.lazy(() => import('users'))
const TeamMemberDetails = React.lazy(() => import('teamMember/Details'))
const TeamMemberPrivateNote = React.lazy(() =>
  import('teamMember/privateNote/Show'),
)
const EditTeamMemberPrivateNote = React.lazy(() =>
  import('teamMember/privateNote/Edit'),
)
const HowToConduct = React.lazy(() => import('howToConduct'))

const NewOneOnOne = React.lazy(() => import('conversations/oneOnOne/New'))
const ShowOneOnOne = React.lazy(() => import('conversations/oneOnOne/Show'))
const EditOneOnOne = React.lazy(() => import('conversations/oneOnOne/Edit'))

const NewCareer = React.lazy(() => import('conversations/career/New'))
const ShowCareer = React.lazy(() => import('conversations/career/Show'))
const EditCareer = React.lazy(() => import('conversations/career/Edit'))

const NewBehavior = React.lazy(() => import('conversations/behavior/New'))
const ShowBehavior = React.lazy(() => import('conversations/behavior/Show'))
const EditBehavior = React.lazy(() => import('conversations/behavior/Edit'))

const NewOnboard = React.lazy(() => import('conversations/onboarding/New'))
const ShowOnboard = React.lazy(() => import('conversations/onboarding/Show'))
const EditOnboard = React.lazy(() => import('conversations/onboarding/Edit'))

const TeamLeaderProfile = React.lazy(() => import('teamLeader'))

const NewUpwardFeedback = React.lazy(() =>
  import('conversations/upwardFeedback/New'),
)

const ShowUpwardFeedback = React.lazy(() =>
  import('conversations/upwardFeedback/Show'),
)

const ListTeamMembersConversations = React.lazy(() => import('teamMember/list'))

const ListTeamLeadersConversations = React.lazy(() => import('teamLeader/list'))

const ListConversationsWithMembers = React.lazy(() => import('Talks'))

const NextConversations = React.lazy(() => import('conversations/next'))

const HumanResourcesPanel = React.lazy(() => import('humanResources'))

const LeaderPanel = React.lazy(() => import('humanResources/leaderProfile'))

export default () => {
  useSiteTitle()
  return (
    <Router className="root-router">
      <Route Component={MyTeams} path="/times" pageTitle="Times" authorize />
      <Route
        Component={EditProfile}
        path="/editar-perfil"
        pageTitle="Editar perfil"
        authorize
      />
      <Route Component={SignIn} path="/login" pageTitle="Login" />
      <Route
        Component={ForgotSent}
        path="/forgot-sent"
        pageTitle="Email com instruções"
      />
      <Route
        Component={ForgotPassword}
        path="/forgot-password"
        pageTitle="Esqueceu sua senha?"
      />
      <Route
        Component={ResetPassword}
        path="/reset-password"
        pageTitle="Reset senha"
      />

      <Route
        Component={NewTeam}
        path="/novo-time"
        pageTitle="Novo time"
        authorize
      />
      <Route
        Component={EditTeam}
        path="/editar-time/:teamId"
        pageTitle="Edição do time"
        authorize
      />
      <Route Component={AcceptInvite} path="/invite" pageTitle="Convite" />
      <Route
        Component={Users}
        path="/usuarios"
        pageTitle="Convide pessoas"
        authorize
      />

      <Route
        Component={TeamMemberDetails}
        path="/membro-do-time/:teamMemberId"
        pageTitle="Membro do time"
        authorize
      />
      <Route
        Component={TeamMemberPrivateNote}
        path="/membro-do-time/:teamMemberId/anotacoes"
        pageTitle="Minhas Anotações"
        authorize
      />
      <Route
        Component={EditTeamMemberPrivateNote}
        path="/membro-do-time/:teamMemberId/anotacoes/editar"
        pageTitle="Editar anotações"
        authorize
      />

      <Route Component={HowToConduct} path="/:name/como-conduzir" authorize />

      <Route
        Component={NewOneOnOne}
        path="/membro-do-time/:teamMemberId/1:1/nova"
        pageTitle="Nova conversa 1:1"
        authorize
      />

      <Route
        Component={ShowOneOnOne}
        path="/membro-do-time/:teamMemberId/1:1/:conversationId"
        pageTitle="Conversa 1:1"
        authorize
      />

      <Route
        Component={EditOneOnOne}
        path="/membro-do-time/:teamMemberId/1:1/edita/:conversationId"
        pageTitle="Edita Conversa 1:1"
        authorize
      />

      <Route
        Component={NewCareer}
        path="/membro-do-time/:teamMemberId/carreira-desenvolvimento/nova"
        pageTitle="Nova conversa de carreira e desenvolvimento pessoal"
        authorize
      />

      <Route
        Component={ShowCareer}
        path="/membro-do-time/:teamMemberId/carreira-desenvolvimento/:conversationId"
        pageTitle="Conversa de carreira e desenvolvimento pessoal"
        authorize
      />

      <Route
        Component={EditCareer}
        path="/membro-do-time/:teamMemberId/carreira-desenvolvimento/edita/:conversationId"
        pageTitle="Edita conversa de carreira e desenvolvimento pessoal"
        authorize
      />

      <Route
        Component={NewBehavior}
        path="/membro-do-time/:teamMemberId/comportamento/nova"
        pageTitle="Nova conversa de comportamento"
        authorize
      />

      <Route
        Component={ShowBehavior}
        path="/membro-do-time/:teamMemberId/comportamento/:conversationId"
        pageTitle="Conversa de comportamento"
        authorize
      />

      <Route
        Component={EditBehavior}
        path="/membro-do-time/:teamMemberId/comportamento/edita/:conversationId"
        pageTitle="Edita conversa de comportamento"
        authorize
      />

      <Route
        Component={NewOnboard}
        path="/membro-do-time/:teamMemberId/onboarding/nova"
        pageTitle="Nova conversa de onboarding"
        authorize
      />

      <Route
        Component={ShowOnboard}
        path="/membro-do-time/:teamMemberId/onboarding/:conversationId"
        pageTitle="Conversa de onboarding"
        authorize
      />

      <Route
        Component={EditOnboard}
        path="/membro-do-time/:teamMemberId/onboarding/edita/:conversationId"
        pageTitle="Edita conversa de onboarding"
        authorize
      />

      <Route
        Component={TeamLeaderProfile}
        path="/lider/:teamLeaderId/"
        pageTitle="Perfil do líder"
        authorize
      />

      <Route
        Component={NewUpwardFeedback}
        path="/lider/:teamLeaderId/feedback/novo"
        pageTitle="Novo feedback"
        authorize
      />

      <Route
        Component={ShowUpwardFeedback}
        path="/lider/:teamLeaderId/feedback/:conversationId"
        pageTitle="Feedback"
        authorize
      />

      <Route
        Component={ListConversationsWithMembers}
        path="/"
        pageTitle="Conversas"
        authorize
      />

      <Route
        Component={ListTeamMembersConversations}
        path="/conversas-com-membros-do-time"
        pageTitle="Conversas com membros dos times"
        authorize
      />

      <Route
        Component={ListTeamLeadersConversations}
        path="/conversas-com-lideres"
        pageTitle="Conversas com líderes"
        authorize
      />

      <Route
        Component={NextConversations}
        path="/proximas-conversas"
        pageTitle="Próximas conversas"
        authorize
      />

      <Route
        Component={HumanResourcesPanel}
        path="/painel"
        pageTitle="Painel do RH"
        authorize
      />

      <Route
        Component={LeaderPanel}
        path="/painel/lider/:teamLeaderId"
        pageTitle="Perfil do líder"
        authorize
      />

      <Route Component={NotFound} default pageTitle="404 - Page not found" />
    </Router>
  )
}
