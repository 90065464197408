import React, { Suspense, useEffect } from 'react'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'
import { useSnackbar } from '@seasonedsoftware/ui'
import { Auth } from 'croods-auth'
import { useScrollAfterLoad } from '@seasonedsoftware/utils'
import Layout from 'ui/Layout'
import Loading from 'ui/Loading'

export const Root = ({ children }) => children

export const redirectUnauthorizedUser = (path, error, message) => async () => {
  await navigate(path)
  error(message)
}

export const Route = ({
  Component,
  authorize,
  unauthorize,
  unauthorizeMessage,
  authRedirect,
  disableRoutingScroll,
  LayoutComponent = Layout,
  ...props
}) => {
  const { pathname } = props.location
  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [props.location])
  useScrollAfterLoad(!disableRoutingScroll)
  const { error } = useSnackbar()
  const path = authRedirect || `/login?redirect_to=${pathname}`
  const message =
    unauthorizeMessage || 'Você não está autorizado a acessar essa página'

  return (
    <LayoutComponent {...props}>
      <Suspense fallback={<Loading />}>
        {authorize ? (
          <Auth
            {...props}
            authorizing={<Loading />}
            unauthorize={unauthorize}
            unauthorized={redirectUnauthorizedUser(path, error, message)}
            Component={Component}
          />
        ) : (
          <Component {...props} />
        )}
      </Suspense>
    </LayoutComponent>
  )
}

Route.propTypes = {
  authRedirect: PropTypes.string,
  Component: PropTypes.elementType.isRequired,
  LayoutComponent: PropTypes.elementType,
  unauthorize: PropTypes.func,
  unauthorizeMessage: PropTypes.string,
  authorize: PropTypes.bool,
  disableRoutingScroll: PropTypes.bool,
  location: PropTypes.object,
}
