import React from 'react'
import PropTypes from 'prop-types'
import { CroodsProvider } from 'croods'
import { authHeaders, saveHeaders } from 'croods-auth'
import { CssBaseline, MuiThemeProvider } from '@material-ui/core'
import { StylesProvider } from '@material-ui/styles'
import { SnackbarProvider } from '@seasonedsoftware/ui'
import { useOnMount, isDev } from '@seasonedsoftware/utils'
import partialRight from 'lodash/partialRight'

import { OrganizationProvider } from 'organization/OrganizationContext'
import ConversationTemplateProvider from 'core/ConversationTemplateProvider'

import Loading from 'ui/Loading'
import theme from 'theme'
import getOrganizationSlug from 'organization/getOrganizationSlug'

async function loadPolyfills() {
  if (typeof window.IntersectionObserver === 'undefined') {
    await import('intersection-observer')
  }
}

const headersOptions = {
  storageKey: `${getOrganizationSlug()}-auth-credentials`,
}

const headers = () => ({
  ...authHeaders(headersOptions),
  Organization: getOrganizationSlug(),
})

const Providers = ({ children }) => {
  useOnMount(loadPolyfills)
  return (
    <CroodsProvider
      handleResponseHeaders={partialRight(saveHeaders, headersOptions)}
      debugRequests={isDev()}
      debugActions={isDev()}
      headers={headers}
      baseUrl={process.env.REACT_APP_API_URL}
      renderLoading={Loading}
      requestTimeout={30000}
      renderError={error => (
        <p style={{ textAlign: 'center', color: 'red' }}>{error}</p>
      )}
    >
      <OrganizationProvider>
        <ConversationTemplateProvider>
          <MuiThemeProvider theme={theme()}>
            <SnackbarProvider>
              <StylesProvider injectFirst>
                <CssBaseline />
                {children}
              </StylesProvider>
            </SnackbarProvider>
          </MuiThemeProvider>
        </ConversationTemplateProvider>
      </OrganizationProvider>
    </CroodsProvider>
  )
}

Providers.propTypes = {
  children: PropTypes.element.isRequired,
}

export default Providers
