import React from 'react'
import PropTypes from 'prop-types'
import { Fetch } from 'croods'
import getOrganizationSlug from './getOrganizationSlug'

export const OrganizationContext = React.createContext()

export class OrganizationProvider extends React.Component {
  render() {
    const slug = getOrganizationSlug()
    return (
      <Fetch
        id={slug}
        name="organization"
        render={organization => (
          <OrganizationContext.Provider value={organization}>
            {this.props.children}
          </OrganizationContext.Provider>
        )}
      />
    )
  }
}

export const OrganizationShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.number.isRequired,
  logo_url: PropTypes.string.isRequired,
  primary_color: PropTypes.string,
  secondary_color: PropTypes.string,
})

OrganizationProvider.propTypes = {
  children: PropTypes.node.isRequired,
  Organization: OrganizationShape,
}

export const OrganizationConsumer = OrganizationContext.Consumer
