import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

export const colors = {
  dodgerblue: '#238afd',
  red: '#ff4f4f',
  yellow: '#f4b546',
  green: '#24c16a',
  whitesmoke: '#f4f4f4',
  lightgrey: 'rgba(0, 0, 0, 0.1)',
  grey: 'rgba(0, 0, 0, 0.4)',
  hardgrey: 'rgba(0, 0, 0, 0.6)',
  white: '#FFF',
  black: '#000',
  black87: 'rgba(0, 0, 0, 87)',
  background: '#fbfbfb',
}

const theme = (
  { primaryColor, secondaryColor } = {
    primaryColor: colors.dodgerblue,
    secondaryColor: colors.white,
  },
) =>
  responsiveFontSizes(
    createMuiTheme({
      palette: {
        primary: { main: primaryColor },
        secondary: { main: secondaryColor },
        background: { main: colors.background },
        text: {
          secondary: colors.hardgrey,
        },
      },
      typography: {
        useNextVariants: true,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        letterSpacing: 'normal',
        h4: {
          fontSize: '34px',
          fontWeight: 900,
          letterSpacing: '0.25px',
        },
        h5: {
          fontSize: '24px',
          fontWeight: 'bold',
        },
        body1: {
          fontSize: '16px',
          lineHeight: '1.5',
          letterSpacing: '0.5px',
        },
        body2: {
          fontSize: '14px',
          lineHeight: 1.43,
          letterSpacing: '0.25px',
        },
        caption: {
          fontSize: '10px',
          fontWeight: 'bold',
          lineHeight: '1.2',
          letterSpacing: '0.33px',
        },
        subtitle1: {
          fontSize: '16px',
          fontWeight: 'bold',
          lineHeight: '1',
          letterSpacing: '0.15px',
        },
        subtitle2: {
          fontSize: '12px',
          lineHeight: 1,
          letterSpacing: '0.09px',
        },
      },
      overrides: {
        MuiButton: {
          root: {
            height: '36px',
            borderRadius: '8px',
          },
          containedPrimary: {
            '&:hover': {
              backgroundColor: '#3592fa',
            },
          },
          containedSecondary: {
            color: colors.dodgerblue,
            '&:hover': {
              backgroundColor: colors.lightgrey,
            },
          },
          label: {
            fontSize: '12px',
            fontWeight: '500',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.33',
            letterSpacing: '0.4px',
            textAlign: 'center',
          },
        },
        MuiCardHeader: {
          root: {
            backgroundColor: colors.whitesmoke,
          },
          title: {
            textAlign: 'center',
            color: colors.hardgrey,
          },
        },
        MuiTableCell: {
          root: {
            borderBottom: 'none',
          },
          head: {
            backgroundColor: colors.background,
            fontWeight: 'bold',
            lineHeight: 1.71,
            color: colors.hardgrey,
          },
          body: {
            fontSize: '14px',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1,
            letterSpacing: '0.1px',
            color: colors.hardgrey,
          },
        },
      },
    }),
  )

export default theme
