import React from 'react'
import { navigate } from '@reach/router'
import { Button } from '@material-ui/core'

export const handleClick = ({ onClick, path }) => event => {
  onClick && onClick(event)
  path && navigate(path)
}

const MenuButton = ({ onClick, path, ...props }) => (
  <Button
    {...props}
    onClick={handleClick({ onClick, path })}
    color="primary"
    css={{ whiteSpace: 'nowrap', textTransform: 'none' }}
  />
)

export default MenuButton
