import React from 'react'
import { Container, Box } from '@material-ui/core'

const Content = ({ children, maxWidth = 'sm', ...props }) => (
  <Container maxWidth={maxWidth}>
    <Box mb={2} {...props}>
      {children}
    </Box>
  </Container>
)

export default Content
