import React from 'react'
import ReactDOM from 'react-dom'
import Providers from 'core/Providers'
import * as serviceWorker from './serviceWorker'

import App from './App'
import './index.css'

ReactDOM.render(
  <Providers>
    <App />
  </Providers>,
  document.getElementById('root'),
)

if (process.env.REACT_APP_REGISTER_SERVICE_WORKER === 'true') {
  serviceWorker.register()
} else {
  serviceWorker.unregister()
}
